<template>
  <div id="app">
    <div v-if="isMobile && !isSafari" ref="InstallApp"
      class="install-app d-block d-md-none relative transition-all duration-300" :class="showInstallApp ? 'visible opacity-100 open' : 'invisible opacity-0'
        ">
      <div
        class="relative flex items-center p-2 bg-pri-900 text-white border-b-2 border-t-0 border-l-0 border-r-0 border-solid border-gray-200"
        @click="onClickOpenApp">
        <div class="absolute top-1 right-2 block cursor-pointer" @click.stop="handleCloseInstallApp">
          <i class="el-icon-error text-lg" />
        </div>
        <div class="flex items-center p-1 bg-white rounded-lg">
          <img class="w-10 h-10" src="../src/assets/images/hodo.png" alt="HODO" />
        </div>
        <div class="pl-2 flex-1">
          <div class="font-bold text-2xl">HODO</div>
          <div class="text-xs">
            Cài đặt và trải nghiệm ứng dụng chăm sóc sức khỏe toàn diện
          </div>
        </div>
      </div>
    </div>
    <template v-if="isLoading">
      <div class="flex flex-col flex-1" v-loading="isLoading" />
    </template>
    <transition name="el-fade-in">
      <div v-show="!isLoading" class="h-full flex flex-col">
        <vue-progress-bar></vue-progress-bar>
        <NavbarTop v-if="showNavbar" />
        <div class="main">
          <!-- <SideBar/> -->
          <component :is="layout">
            <router-view></router-view>
          </component>
          <ScrollToTop v-if="isHiddenWhitePublicOrBlankLayout" />
        </div>
        <Footer v-if="showFooter"></Footer>
        <ProgressBox v-if="$singleLoading.show" :value="$singleLoading.value" :name="$singleLoading.name"
          :title="$singleLoading.title" />
        <MultiUploadProgressBox v-if="$multiUploading && $multiUploading.length" />
      </div>
    </transition>
  </div>
</template>

<script>
import Echo from 'laravel-echo'
import ScrollToTop from './components/Helpers/ScrollToTop'
import Footer from './components/Layout/Footer'
import NavbarTop from './components/Layout/NavbarTop'
import SideBar from './components/Layout/SideBar.vue'
import MultiUploadProgressBox from './components/Utilities/MultiUploadProgressBox.vue'
import ProgressBox from './components/Utilities/ProgressBox'
import appUtils from './utils/appUtils'
window.io = require('socket.io-client')
const defaultLayout = 'app'
export default {
  name: 'App',
  components: {
    NavbarTop,
    Footer,
    ScrollToTop,
    ProgressBox,
    SideBar,
    MultiUploadProgressBox
  },
  data() {
    return {
      isLoading: true,
      progress: {
        show: false,
        value: 0,
        name: '',
        title: ''
      },
      showInstallApp: false
    }
  },
  async created() {
    const self = this

    self.handleOpenApp()

    window.$router = this.$router
    window.$toast = this.$toast

    const cartData = localStorage.getItem('HodoCart')
    self.$shoppingCart = JSON.parse(cartData) || []

    if (appUtils.getLocalUser()) {
      this.$user = appUtils.getLocalUser()
      this.onConnectSocket()
    }
    if (localStorage.getItem('code')) {
      await self.getOrgAndWs(localStorage.getItem('code'))
    } else {
      await self.getOrgAndWs()
    }
    window.addEventListener('beforeunload', function (e) {
      self.leaving(e)
    })
  },
  computed: {
    isHiddenWhitePublicOrBlankLayout() {
      return !(
        this.$route.meta.layout === 'white-public' ||
        this.$route.meta.layout === 'blank'
      )
    },
    showNavbar() {
      if (
        this.$route.meta.layout === 'public' ||
        this.$route.meta.layout === 'blank' ||
        this.$route.meta.layout === 'white-public' ||
        this.$route.meta.layout === 'auth'
      ) {
        return false
      } else {
        return true
      }
    },
    showFooter() {
      if (
        this.$route.meta.layout === 'white-public' ||
        this.$route.meta.layout === 'blank'
      ) {
        return false
      } else {
        return true
      }
    },
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    isMobile() {
      return appUtils.checkIsMobile()
    },
    isSafari() {
      return appUtils.checkIsSafari()
    }
  },
  mounted() {
    if (appUtils.getLocalLang()) {
      window.$lang = appUtils.getLocalLang()
    } else {
      window.$lang = 'vi'
    }
    window.addEventListener('storage', this.onStorageUpdate)
    window.$uploadPacsFile = this.onUploadPacs
  },

  watch: {
    '$route.meta': {
      handler(data) {
        if (data) {
          // this.isLoading = false
        }
      },
      deep: true
    },
    '$route.query': {
      async handler(data) {
        const self = this
        if (data?.code) {
          localStorage.setItem('code', data?.code)
          await self.getOrgAndWs(data?.code)
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.onStorageUpdate)
  },
  methods: {
    handleOpenApp() {
      const self = this
      try {
        if (!self.isMobile) return
        // window.location = 'hodopatient://'
        const clickedAt = +new Date()
        setTimeout(function () {
          if (+new Date() - clickedAt < 2000) {
            self.showInstallApp = true
            // self.onClickOpenApp()
          }
        }, 300)
      } catch (error) {
        console.log(error)
      }
    },
    onClickOpenApp() {
      window.open('https://hodo.app/#/go', '_blank')
    },
    handleCloseInstallApp() {
      this.$refs.InstallApp.remove()
    },
    async onConnectSocket() {
      // Have this in case you stop running your laravel echo server
      if (typeof io !== 'undefined') {
        if (appUtils.getLocalToken()) {
          // let user = this.$user
          this.$echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_ECHO_URL,
            auth: {
              headers: {
                Authorization: `Bearer ${appUtils.getLocalToken()}`
              }
            }
          })
          this.$conectedEcho = true
          await this.$echo.connector.socket.on('connect', () => {
            console.log('Connected to Echo Server')
          })
        }
      }
    },
    async getOrgAndWs(code) {
      try {
        const self = this
        self.isLoading = true

        if (!code) {
          const domain =
            window.location.hostname === 'localhost'
              ? 'dev.hodo.com.vn'
              : window.location.hostname
          const params = {
            domain
          }
          await self.$store
            .dispatch('registerMedical/fetchDefaultOrgAndWsNonCode', params)
            .then((response) => {
              self.isLoading = false
              // self.$router
              //   .replace({
              //     name: 'Home'
              //   })
              //   .catch(() => {})
            })
            .catch((e) => {
              console.log(e)
              self.isLoading = false
              if (process.env.VUE_APP_EXAMINATION_REGISTER === 'true') {
                window.$toast.open({
                  message: 'Lỗi lấy thông tin cơ sở y tế',
                  type: 'danger'
                })
              }
            })
          return
        }

        await self.$store
          .dispatch('registerMedical/fetchDefaultOrgAndWs', {
            code
          })
          .then((response) => {
            self.isLoading = false
            // self.$router
            //   .replace({
            //     name: 'Home'
            //   })
            //   .catch(() => {})
          })
          .catch((e) => {
            console.log(e)
            self.isLoading = false
            if (process.env.VUE_APP_EXAMINATION_REGISTER === 'true') {
              window.$toast.open({
                message: 'Lỗi lấy thông tin cơ sở y tế',
                type: 'danger'
              })
            }
          })
      } catch (error) {
        console.log(error)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    leaving(e) {
      if (this.$uploading) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    onStorageUpdate() {
      if (window.$lang !== appUtils.getLocalLang()) {
        window.$lang = appUtils.getLocalLang()
      }
    },
    onUploadPacs(access, file) {
      const self = this
      if (!access || !file) return
      let bucket = null
      const region = 'ap-southeast-1'
      const IdentityPoolId =
        'ap-southeast-1:ff53cfbf-2595-4ade-83b8-43e3ad25f279'
      const bucketName = 'hodo-pacdicom'
      const prefix = 'pacdicom'

      window.AWS.config.region = region // 1. Enter your region
      window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials(
        {
          IdentityPoolId: IdentityPoolId // 2. Enter your identity pool
        }
      )
      window.AWS.config.credentials.get(function (err) {
        if (err) alert(err)
        // console.log(AWS.config.credentials);
      })

      bucket = new window.AWS.S3({
        params: {
          Bucket: bucketName
        }
      })

      if (file) {
        const objKey =
          prefix +
          '/' +
          access.person_diseases_id +
          '/' +
          access.accession +
          '.' +
          file.name.split('.').pop()
        const params = {
          Key: objKey,
          ContentType: file.type,
          Body: file
          // ACL: 'public-read'
        }
        this.$singleLoading.show = true
        this.$singleLoading.title = 'Đang tải lên Ảnh DICOM'
        this.$singleLoading.name = file.name
        self.$uploading = true
        try {
          bucket
            .upload(params)
            .on('httpUploadProgress', function (evt) {
              var uploaded = Math.round((evt.loaded / evt.total) * 100)
              console.log(`File uploaded: ${uploaded}%`)
              self.$singleLoading.value = uploaded
            })
            .send(function (err, data) {
              if (err) {
                // an error occurred, handle the error
                console.log('upload failed', err, err.stack)
                return
              }
              self.pacUpdate(data.Key, access)
              setTimeout(() => {
                self.$singleLoading = {
                  show: false,
                  value: 0,
                  name: '',
                  title: ''
                }
              }, 1500)
            })
        } catch (error) {
          self.$uploading = false
        }
      } else {
        alert('Chưa chọn file')
      }
    },
    async pacUpdate(url, access) {
      const self = this
      const params = {
        files: url
      }
      await self.$rf
        .getRequest('AuthRequest')
        .pacUpdate(access.id, params)
        .finally(() => {
          self.$uploading = false
        })
    },
    async getLinkDownload(name, access) {
      const self = this
      const params = {
        fileName: name
      }
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getLinkDownloadAws(params, header)
        .then((r) => {
          if (r?.data?.data) self.pacUpdate(r?.data?.data, access)
        })
    }
  }
}
</script>
<style lang="scss">
:root {
  --primary: rgba(32, 64, 155, 1);
  --grey: rgba(228, 231, 236, 1);
  --dark: #1e293b;
  --dark-alt: rgba(228, 231, 236, 1);
  --light: #f1f5f9;
  --sidebar-width: 300px;
  --background-sidebar: rgba(249, 250, 251, 1);
  --text-color: rgba(71, 84, 103, 1);
}

#app {
  color: #292929;
  max-width: 100vw;
  overflow-x: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #292929;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-loading-mask {
  z-index: 1 !important;
}
</style>
